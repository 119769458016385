#navbar, #footer {
    background: #232f3e;
    padding: 1rem 4rem;
}

#navbar {
    position: sticky;
    top: 0;
    z-index: 1000;
}

#footer {
    position: sticky;
    bottom: 0;
}

.center-text {
    text-align: center;
}

.right-text {
    text-align: right;
}

.left-text {
    text-align: left;
}

.text-white {
    color: #fff;
}

.menu-list a {
    cursor: pointer;
    text-decoration: none;
    color: #ccc;
    font-size: 1.5rem;
}

.menu-list a:hover {
    color: lightgray;
}

.menu-list a.title {
    font-size: 2.5rem;
}

.menu-list > title > div {
    font-style: italic;
}

.menu-list .selected {
    font-weight: 600;
}

.basket-container {
    text-align: center;
    height: 40px;
}

.basket-image {
    width: 32px;
    text-align: center;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}

.menu-list .shopping-basket-navbar-quantity {
    display: block;
    text-align: center;
    color: white;
    line-height: 14px;
    font-weight: bold;
}

#footer p {
    color: #fff;
}

#footer a {
    color: #fff;
    font-weight: bold;
}

.actions-list-icon-link {
    color: gray;
}

img.fit {
    max-width: 100px;
    object-fit: contain;
    margin: auto;
    display: block;
    max-height: 100px;
}

.business-group {
    list-style: none;
}

.mr-10 {
    margin-right: 10px;
}

.spinner-visible {
    display: block;
}

.spinner-hidden {
    display: none;
}

.visible {
    display: block;
}

.hidden {
    display: none;
}

.restricted-green-background {
    background-color: darkgreen;
    text-align: center;
    border-radius: 50%;
}

.new-from-lw-badge {
    background-color: #232F3E;
    height: 24px;
    display: flex;
    max-width: 44px;
}

.new-from-lw-badge-text {
    margin: 8px;
    color: white;
    line-height: 10px;
}

.img-fit-grid {
    width: 100%;
}